<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.714 7.071H10.929V1.286C10.929 0.944931 10.7935 0.617831 10.5523 0.376659C10.3112 0.135488 9.98407 0 9.643 0H8.357C8.01593 0 7.68883 0.135488 7.44766 0.376659C7.20649 0.617831 7.071 0.944931 7.071 1.286V7.072H1.286C0.945105 7.072 0.618157 7.20735 0.377014 7.44831C0.135871 7.68926 0.000265082 8.0161 0 8.357L0 9.643C0 9.98407 0.135489 10.3112 0.376661 10.5523C0.617832 10.7935 0.944932 10.929 1.286 10.929H7.071V16.715C7.07127 17.0559 7.20687 17.3827 7.44801 17.6237C7.68916 17.8646 8.0161 18 8.357 18H9.643C9.98407 18 10.3112 17.8645 10.5523 17.6233C10.7935 17.3822 10.929 17.0551 10.929 16.714V10.929H16.715C17.0559 10.9287 17.3827 10.7931 17.6237 10.552C17.8646 10.3108 18 9.9839 18 9.643V8.357C18 8.18812 17.9667 8.02089 17.9021 7.86487C17.8375 7.70884 17.7428 7.56708 17.6233 7.44766C17.5039 7.32825 17.3622 7.23352 17.2061 7.16889C17.0501 7.10426 16.8829 7.071 16.714 7.071Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
